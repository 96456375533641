.row {
  margin: 4rem 0;
}

.companyName {
  line-height: 2.5rem;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
}

.contact a {
  text-decoration: none;
}

.columnRight {
  flex-grow: 1;
  justify-content: flex-end;
}

.columnLeft {
  flex-grow: 0.5;
  /* margin: auto; */
  justify-content: flex-end;
  z-index: 50;
}

.columnLeft h1 {
  font-weight: 500;
}

.columnLeft h3 {
  font-family: "Lato", sans-serif;
}

.columnLeft p {
  font-family: "Poppins", sans-serif;
  margin-left: 1rem;
}

.columnLeft,
.columnRight,
.enterLeft,
.enterRight {
  opacity: 0;
}

.enterLeftActive {
  animation: enterLeft 0.2s forwards;
  opacity: 1;
}

.enterRightActive {
  animation: enterRight 0.2s forwards;
  opacity: 1;
}

.enterLeftDone,
.enterRightDone {
  opacity: 1;
}

@keyframes enterLeft {
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

@keyframes enterRight {
  from {
    opacity: 0;
    transform: translateX(5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}
