.cookie {
  /* opacity: 0; */
  /* display: none; */
  position: sticky;
  bottom: 0;
  background-color: #cacaca;
  padding: 10px 25px;
  min-width: 100%;
}

.cookie p {
  padding: 1rem 0;
  margin: auto;
}

.cookie button {
  float: right;
  margin-left: 15px;
}

.fadeEnter {
  opacity: 0 !important;
  display: block;
}

.fadeEnterActive {
  opacity: 1 !important;
  transition: opacity 200ms;
  display: block;
}

.fadeEnterDone {
  opacity: 1 !important;
  display: block;
}

.fadeExit {
  opacity: 1 !important;
}

.fadeExitActive {
  opacity: 0 !important;
  transition: opacity 200ms;
}
