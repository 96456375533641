.container {
  height: 350px;
  width: 350px;
  overflow: hidden;
}

.container a {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
}

.horizontalLine {
  background-color: #6791ac;
  width: 80px;
  height: 7px;
  margin: 1.5rem 0;
}

.cardIcon {
  margin: 1rem 0;
  font-size: 2rem;
  color: var(--color-secondary);
}
