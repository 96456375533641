.navbar li {
  float: right;
}

.navbar li a {
  display: block;
  color: var(--color-text-navbar);
  text-align: center;
  padding: 14px 0 14px 22px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

.navbarIcon {
  vertical-align: super;
  margin: 0;
  padding: 0;
  font-weight: 500;
  transition: transform 0.4s ease-in-out;
  display: inline-block;
}

.flipped {
  transform: rotate(180deg);
}
