.legal {
  font-family: "Roboto", sans-serif;
  font-weight: thin;
  border-top: thin solid #ddd;
}

.legal p {
  font-style: italic;
}
.legal h2 {
  margin-top: 25px;
}

.legal ol li::before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
.legal ol li {
  display: block;
}
.legal h3 {
  display: inline;
  font-size: 12pt;
}
.legal ol li.list-header {
  font-size: 13pt;
  margin-top: 15px;
}
.legal ol li.list-header ol li {
  font-size: 11pt;
  margin-top: 10px;
}

.legal ol {
  counter-reset: item;
  padding-left: 1rem !important;
}
.legal ul {
  content: none;
  counter-increment: initial;
  list-style: lower-alpha;
}
.legal ul li:before {
  content: none;
}

.legal ol {
  margin-bottom: 2rem;
}

@media screen and (max-width: 639px) {
  .container {
    max-width: 95%;
    margin-right: 1.5rem;
  }
  .header {
    margin: 0.5rem;
  }
  .header h2 {
    font-size: 1.2rem;
  }
  .header p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
  .container {
    max-width: 90%;
    margin-right: 3rem;
  }
  .header {
    margin: 0.5rem;
  }
  .header h2 {
    font-size: 1.5rem;
  }
  .header p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1008px) and (max-width: 1439px) {
  .container {
    max-width: 90%;
  }
  .header {
    margin: 0.5rem;
  }
  .header h2 {
    font-size: 1.5rem;
  }
  .header p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1440px) {
}
