.header {
  background-color: rgb(240, 240, 240);
  padding: 0 !important;
  margin: 0 !important;
}

.headerRow {
  height: 325px;
  align-items: center;
  text-align: center;
  font-family: "Source Serif 4", serif;
  color: var(--color-text-pageHeader);
}

.headerRow h1 {
  font-weight: 800;
}

.headerRow p {
  padding: 1rem 0;
  font-size: 1.3rem;
  width: 60%;
  margin: auto;
  line-height: 1.5;
}
