@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap");

:root {
  --default-page-width: 68%;
  --color-link-hover: #7f7f7f;
  --color-primary: #729eba;
  --color-secondary: #3d5f75;
  --color-border: rgb(207, 207, 207);
  --color-text-footer: #e6e6e6;
  --color-text-navbar: rgb(65, 65, 65);
  --color-text-pageHeader: #fff;
  --color-navbar-primary: #f9f9f9;
  --color-text-default: #554e4e;
}

.btn-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  text-transform: uppercase;
  font-weight: 600;
}

.btn-primary:hover {
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  color: var(--text-color);
  background-color: rgb(255, 255, 255);
}
