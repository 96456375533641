.navmenu {
  opacity: 0;
  display: none;
  /* padding: 3rem 0; */
  background-color: var(--color-navbar-primary);
  position: absolute;
  width: 100%;
  z-index: 25;
  left: 0;
  border-top: 1px solid var(--color-border);
  margin-top: 10px;
}

.navmenu ul {
  list-style-type: none;
  margin: 0;
  margin-top: 3rem;
  padding: 0;
  overflow: hidden;
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.navmenu ul li a {
  display: block;
  padding: 0.8rem 1rem;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  text-decoration: none;
  color: var(--color-text-navbar);
}

.navmenu h1 {
  font-family: "Source Serif 4", serif;
  font-weight: 700;
  font-size: 3rem;
  padding: 1rem 0;
  color: var(--color-text-navbar);
}

.navmenu p {
  line-height: 1.5;
  font-family: "Lato";
  font-size: 1.1rem;
}

.splash a {
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.splash p {
  margin: 10px 0;
}

.icon {
  font-size: 1.3rem;
}

.links {
  padding-top: 2rem;
  padding-left: 6rem;
  justify-content: flex-end;
}

.links ul li a {
  text-align: left;
}

.fadeEnter {
  opacity: 0 !important;
  display: block;
}

.fadeEnterActive {
  opacity: 1 !important;
  transition: opacity 200ms;
  display: block;
}

.fadeEnterDone {
  opacity: 1 !important;
  display: block;
}

.fadeExit {
  opacity: 1 !important;
}

.fadeExitActive {
  opacity: 0 !important;
  transition: opacity 200ms;
}
