.container {
  /* padding: 4rem 0; */
  justify-content: space-between;
  font-family: "Lato", sans-serif;
  height: 500px;
}

.headerContainer {
  height: 75vh;
}

.headerContainer button {
  font-size: 2rem;
}

.row {
  padding: 3rem 0;
}

.columnLeft {
  z-index: 1;
}

.cards {
  background-color: var(--color-secondary);
}

.columnLeft p {
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--color-text-default);
}

.textShadowLg {
  text-shadow: 3px 2px 1px #000000; /* x-offset, y-offset, blur radius, color; */
}

.textShadowSm {
  text-shadow: 1px 1px 1px #000000; /* x-offset, y-offset, blur radius, color; */
}

.columnLeft h1 {
  font-size: 4rem;
  font-weight: 700;
}

.cardRow {
  justify-content: center;
  margin: 4rem 0;
  gap: 1rem;
}

.partners {
  height: 300px;
  border-top: thin var(--color-border) solid;
}

.partnerRow {
  gap: 1.5rem;
}

.contact {
  height: 300px;
  font-family: "Lato", sans-serif;
}
