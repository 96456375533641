.control {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.control label {
  font-size: 0.9rem;
}

.control input,
.control textarea,
.control select {
  padding: 5px 10px;
  background-color: #ffffffda;
  border: thin #bbb solid;
  border-radius: 5px;
  line-height: 1.6;
  font-family: inherit;
  font-size: 1rem;
}

.control.invalid label {
  color: rgb(252, 89, 89);
}

.control.invalid input,
.control.invalid textarea {
  border: thin solid rgb(241, 114, 114);
  background-color: rgb(255, 205, 205);
}
