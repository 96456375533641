.backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 67vh; */
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}
