.header {
  border-bottom: 1px solid var(--color-border);
}

.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.header li {
  display: inline;
}

.header li a {
  color: var(--color-text-navbar);
  text-align: center;
  padding: 0 0.2rem 0 2rem;
  text-decoration: none;
  font-size: 0.8rem;
  font-family: "Lato", sans-serif;
}

.header li a:hover {
  color: var(--color-link-hover);
}

.icon {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
}

.topbar {
  border-bottom: 1px solid var(--color-border);
}

.topbar ul {
  float: right;
  padding: 10px 0;
}

.topbar ul li a {
  text-transform: uppercase;
}

.bottombar {
  margin: 10px 0;
}
