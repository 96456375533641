.footer {
  background-color: rgb(45, 65, 83);
  height: 400px;
  color: var(--color-text-footer);
  padding: 3rem 0;
  font-size: 0.9rem;
}

.footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer p {
  color: white;
  margin: 1rem 0;
  line-height: 1.5;
  font-family: "Lato", sans-serif;
}

.footer h4 {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding-top: 3rem;
}

.footer ul li a {
  text-decoration: none;
  font-family: "Lato", sans-serif;
  color: var(--color-text-footer);
  font-size: 0.9rem;
}

.footer ul li {
  margin: 0.5rem 0;
}

.links {
  margin-left: 4rem;
}

.social svg {
  margin-right: 7px;
  font-size: 1.2rem;
}

.social a {
  color: inherit;
}

.copyright p {
  margin: 0;
}
