.form {
  /* padding: 2rem; */
}

.form input,
textarea {
  line-height: 2.3rem;
  margin: 0.2rem 0;
}

.captcha {
  float: right;
  /* display: inline-block; */
  margin-left: auto;
}

.formActions {
  /* margin-left: auto; */
  float: right;
}

.formActions button {
  /* float: right; */
  margin: 0 0 5px 0;
  width: 100%;
}
